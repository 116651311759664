import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=LinkText wrap=flex",
        "componentName": "LinkText",
        "wrap": "flex"
      }}>{`<Switch id="switch" label="Game mode" message="Zelda" />
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Switch" mdxType="Props" />
    <h2 {...{
      "id": "action",
      "style": {
        "position": "relative"
      }
    }}>{`Action`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Adds an action or extra information to your item, mostly used for links.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Switch
  id="action"
  label="Label"
  action={<LinkText href="#">LinkText</LinkText>}
/>
`}</code></pre>
    <h2 {...{
      "id": "checked",
      "style": {
        "position": "relative"
      }
    }}>{`Checked`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Make a Switch selected by passing the `}<inlineCode parentName="p">{`checked`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Switch id="checked" label="Label" checked />
`}</code></pre>
    <h2 {...{
      "id": "disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Disabled`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Marking a Switch as `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, will disable all interaction with the element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Switch id="disabled" label="Label" disabled />
`}</code></pre>
    <h2 {...{
      "id": "error",
      "style": {
        "position": "relative"
      }
    }}>{`Error`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To convey an error to the user, you can set the `}<inlineCode parentName="p">{`error`}</inlineCode>{` prop to true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Switch
  id="error"
  label="Label"
  message="The visual style is only passed to the message."
  error
/>
`}</code></pre>
    <h2 {...{
      "id": "labelhidden",
      "style": {
        "position": "relative"
      }
    }}>{`LabelHidden`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To meet accessibility requirements, every Switch should have an associated
label. We do support the ability to visually hide the label when the Switch's
purpose is clear.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Switch id="labelHidden" label="Label" labelHidden />
`}</code></pre>
    <h2 {...{
      "id": "labelplacement",
      "style": {
        "position": "relative"
      }
    }}>{`LabelPlacement`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`The positioning of the label against the switch itself. The full label will move
position (also action, message & optionalLabel).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Switch mb={5} id="labelPlacementNone" label="Undefined" />
<Switch mb={5} id="labelPlacementLeft" label="Left" labelPlacement="left" />
<Switch id="labelPlacementRight" label="Right" labelPlacement="right" />
`}</code></pre>
    <h2 {...{
      "id": "labelprops",
      "style": {
        "position": "relative"
      }
    }}>{`LabelProps`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`You can visually change the label of the Switch. For more info about the
possibilities, check the `}<a parentName="p" {...{
        "href": "/components/text/code/"
      }}>{`Text component's`}</a>{`
documentation (Label inherits the Text).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Switch
  mb={5}
  id="labelProps"
  label="Label"
  labelProps={{
    color: 'primaryBase',
    decoration: 'line-through',
    fontFamily: 'primary',
    fontWeight: 'regular',
    size: 'Heading2',
    textTransform: 'capitalize',
  }}
/>
`}</code></pre>
    <h2 {...{
      "id": "message",
      "style": {
        "position": "relative"
      }
    }}>{`Message`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Give additional information to the user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Switch
  id="message"
  label="Label"
  message="Message can be set to provide additional information."
/>
`}</code></pre>
    <h2 {...{
      "id": "optionallabel",
      "style": {
        "position": "relative"
      }
    }}>{`OptionalLabel`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Compared with TextField, the Switch is `}<strong parentName="p">{`not required`}</strong>{` by default. The
optionalLabel is additional feedback to the user, please provide it where
needed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Switch id="optionalLabel" label="Label" optionalLabel="I'm optional" />
`}</code></pre>
    <h2 {...{
      "id": "required",
      "style": {
        "position": "relative"
      }
    }}>{`Required`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Make the field required if needed. If you already added optionalLabel, the field
will stay optional to not confuse the user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Switch id="required" required label="Label" />
`}</code></pre>
    <h2 {...{
      "id": "value",
      "style": {
        "position": "relative"
      }
    }}>{`Value`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Use value for controlled Switches.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [form, setForm] = React.useState({
    switch1: false,
    switch2: true,
  });

  const handleSwitchChange = ({ target }) => {
    setForm({
      ...form,
      [target.value]: !form[target.value],
    });
  };

  return (
    <React.Fragment>
      <Switch
        id="switch1"
        label="Label 1"
        value="switch1"
        checked={form.switch1}
        onChange={handleSwitchChange}
        mb={5}
      />
      <Switch
        id="switch2"
        label="Label 2"
        value="switch2"
        checked={form.switch2}
        onChange={handleSwitchChange}
      />
    </React.Fragment>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      